import '../styles/globals.css'
import '../styles/card.css'
import type { AppProps } from 'next/app'
import { AnimatePresence } from 'framer-motion'


function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AnimatePresence
        exitBeforeEnter
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
    >
      <Component {...pageProps} />
    </AnimatePresence>
  )
}

export default MyApp
